@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

tr {
  cursor: pointer;
}

table tr.highlight td {
  background-color: var(--color-success-500) !important;
  color: #ffffff;
}

table tr.highlight-red td {
  background-color: var(--color-danger-500) !important;
  color: #ffffff;
}

td {
  cursor: pointer;
  margin: 15px 0;
}

.form-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.form-label {
  font-weight: 600;
  color: #495057;
}

.form-control {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%; /* Ancho al 100% */
  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.form-select {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%; /* Ancho al 100% */
  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}


/* Table Styles */

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

// Test

.table-caption {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #7A5100;
}

.col-sub-total {
  width: 50px;
}

.form-title {
  text-align: right;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.custom-total-input {
  width: auto;
  display: inline-block;
}

.form-status {
  color: white;
  padding: 0.2rem 0.5rem;
}

td.estado-solicitud {
  background-color: var(--color-warning-400) !important;
  color: #ffffff !important;
}

td.estado-rechazado {
  background-color: var(--color-danger-400) !important;
  color: #ffffff !important;
}

td.estado-aprobado-coordinacion {
  background-color: var(--color-info-400) !important;
  color: #ffffff !important;
}

td.estado-pre-aprobado {
  background-color: var(--color-info-500) !important;
  color: #ffffff !important;
}

td.estado-autorizado-administracion {
  background-color: var(--color-success-400) !important;
  color: #ffffff !important;
}

td.estado-provisorio {
  background-color: var(--color-success-500) !important;
  color: #ffffff !important;
}

td.estado-definitivo {
  background-color: var(--color-success-600) !important;
  color: #ffffff !important;
}

td.estado-devengado {
  background-color: var(--color-success-700) !important;
  color: #ffffff !important;
}

td.estado-abonado {
  background-color: var(--color-success-800) !important;
  color: #ffffff !important;
}

td.estado-finalizado {
  background-color: var(--color-success-900) !important;
  color: #ffffff !important;
}

td.estado-cancelado {
  background-color: var(--color-danger-500) !important;
  color: #ffffff !important;
}


[nbButton].appearance-outline.status-primary, .appearance-outline.status-primary[nbButtonToggle] {
  background-color: var(--color-info-200);
  border-color: var(--color-info-700);
  color: var(--color-info-700);
  &:hover {
    background-color: var(--color-info-300);
    border-color: var(--color-info-700);
    color: var(--color-info-700);
  }
}

[nbButton].appearance-outline.status-warning, .appearance-outline.status-warning[nbButtonToggle] {
  background-color: var(--color-warning-200);
  border-color: var(--color-warning-700);
  color: var(--color-warning-700);
  &:hover {
    background-color: var(--color-warning-300);
    border-color: var(--color-warning-700);
    color: var(--color-warning-700);
  }
}

[nbButton].appearance-outline.status-success, .appearance-outline.status-success[nbButtonToggle] {
  background-color: var(--color-success-200);
  border-color: var(--color-success-700);
  color: var(--color-success-700);
  &:hover {
    background-color: var(--color-success-300);
    border-color: var(--color-success-700);
    color: var(--color-success-700);
    font-weight: 600;
  }
}

.buttons-stripe {
  display: flex;
  justify-content: space-between;
  
  button:last-child { margin-right: 0 !important; }
 
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    button {
      flex: 0 0 30%;
      &:nth-child(3) {
        margin-right: 0 !important;
      }
      &:nth-child(-n+3) {
        margin-bottom: 15px !important;
      }

    }
  }

}

// Pagination
*:not(.active) > .page-link {
  color: var(--color-primary-700) !important;
}
.page-link.active, .active > .page-link {
  background-color: var(--color-primary-700) !important;
  border-color: var(--color-primary-700) !important;
}

// Forms
.col-sm-9 {
  select, input, textarea {
    max-width: var(--input-medium-max-width);
  }
  nb-select {
    width: var(--input-medium-max-width);  
  }
}
.form-container {
  [nbInput].status-basic {
    background-color: white;
  }
}

.link-subtle-danger, .link-subtle-info {
  color: #140a74;
  cursor: pointer; 
}
.link-subtle-info {
  &:hover {
    color: var(--color-warning-700);
  }
}

.link-subtle-danger {
  &:hover {
    color: var(--color-danger-700);
  }
}

.filetype-icon {
  > * {
    vertical-align: middle;
    display: inline-block;
  }
  a {
    margin-left: 8px;
    font-size: 85%;
  }
  .actions {
    display: none;
  }
  &:hover {
    .actions {
      display: inline-block;
      cursor: pointer;
    }
  }
}

// Accordion

.accordion-wrapper {
  margin-bottom: 5px !important;
}

// Accordion header
.formulario-accordion-header {
  background-color: #D30A15 !important;
  border-radius: 5px !important; 
  color: #ffffff !important;  
  font-size: 14px !important;  
  line-height: 1.5 !important;  
  display: flex; 
  justify-content: space-between; 
}

.acoordion-header-form-status {
  color:  #ffffff;
  margin-right: 30px;
}

